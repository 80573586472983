import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "filters" }
const _hoisted_2 = { class: "status-filter" }
const _hoisted_3 = { class: "date-filter" }
const _hoisted_4 = { class: "reservation-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_header = _resolveComponent("page-header")!
  const _component_button_tab = _resolveComponent("button-tab")!
  const _component_input_date_picker = _resolveComponent("input-date-picker")!
  const _component_button_basic = _resolveComponent("button-basic")!
  const _component_reservation_item = _resolveComponent("reservation-item")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_page_header, {
      title: "Lecture 예약내역",
      "back-button": true,
      onBackBtnAction: _cache[0] || (_cache[0] = ($event: any) => (_ctx.actions.goToLectures()))
    }),
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_button_tab, {
            text: "전체",
            active: !_ctx.state.filters.status,
            onAction: _cache[1] || (_cache[1] = ($event: any) => (_ctx.getReservations({ status: '' })))
          }, null, 8, ["active"]),
          _createVNode(_component_button_tab, {
            text: "예정",
            active: _ctx.state.filters.status === 'COMPLETED',
            onAction: _cache[2] || (_cache[2] = ($event: any) => (_ctx.getReservations({ status: 'COMPLETED' })))
          }, null, 8, ["active"]),
          _createVNode(_component_button_tab, {
            text: "지난",
            active: _ctx.state.filters.status === 'FINISHED',
            onAction: _cache[3] || (_cache[3] = ($event: any) => (_ctx.getReservations({ status: 'FINISHED' })))
          }, null, 8, ["active"]),
          _createVNode(_component_button_tab, {
            text: "취소된",
            active: _ctx.state.filters.status === 'CANCELED',
            onAction: _cache[4] || (_cache[4] = ($event: any) => (_ctx.getReservations({ status: 'CANCELED' })))
          }, null, 8, ["active"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_input_date_picker, {
            date: _ctx.state.filters.startAt,
            "enable-time": false,
            placeholder: "from date",
            format: "YYYY-MM-DD",
            onUpdateDate: _cache[5] || (_cache[5] = (value) => _ctx.actions.updateStartAt(value))
          }, null, 8, ["date"]),
          _createVNode(_component_input_date_picker, {
            date: _ctx.state.filters.endAt,
            "enable-time": false,
            placeholder: "to date",
            format: "YYYY-MM-DD",
            onUpdateDate: _cache[6] || (_cache[6] = (value) => _ctx.actions.updateEndAt(value))
          }, null, 8, ["date"]),
          _createVNode(_component_button_basic, {
            text: "가져오기",
            onAction: _cache[7] || (_cache[7] = ($event: any) => (_ctx.getReservations()))
          })
        ])
      ]),
      _createElementVNode("ul", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.lecturesReservations, (reservation) => {
          return (_openBlock(), _createElementBlock("li", {
            key: `reserve-item-${reservation.resourceId}`
          }, [
            _createVNode(_component_reservation_item, {
              reservation: reservation,
              "lecture-resource-id": _ctx.lectureResourceId,
              onCancelSchedule: ($event: any) => (_ctx.actions.cancelSchedule(reservation))
            }, null, 8, ["reservation", "lecture-resource-id", "onCancelSchedule"])
          ]))
        }), 128))
      ])
    ])
  ], 64))
}