
import { computed, defineComponent, PropType, reactive } from "vue";
import { LecturesReservation } from "@/types/lectures";
import moment from "moment-timezone";
import ButtonAccordion from "@/components/console/buttons/ButtonAccordion.vue";
import ArrowIcon from "@/components/console/icons/ArrowIcon.vue";

export default defineComponent({
  name: "ReservationItem",
  components: { ArrowIcon, ButtonAccordion },
  props: {
    reservation: {
      type: Object as PropType<LecturesReservation>,
      required: true,
    },
    lectureResourceId: {
      type: String,
      required: true,
    },
  },
  emits: ["cancelSchedule"],
  setup(props, { emit }) {
    const state = reactive({
      schedule: computed(() => {
        return `${moment(props.reservation.startAt).format(
          "YYYY-MM-DD HH:mm"
        )} - ${moment(props.reservation.endAt).format("YYYY-MM-DD HH:mm")}`;
      }),
      showUsers: false,
      arrowDirection: computed(() => {
        return state.showUsers ? "up" : "down";
      }),
    });

    const actions = {
      toggleShowUsers: () => {
        state.showUsers = !state.showUsers;
      },
      cancelReservedSchedule: () => {
        emit("cancelSchedule", props.reservation);
      },
    };

    return { state, actions };
  },
});
