
import { computed, defineComponent, onBeforeMount, reactive } from "vue";
import { useRoute, useRouter } from "vue-router";
import PageHeader from "@/components/console/headers/PageHeader.vue";
import { useStore } from "vuex";
import ReservationItem from "@/pages/console/LectureReservations/ReservationItem/ReservationItem.vue";
import ButtonTab from "@/components/console/buttons/ButtonTab.vue";
import InputDatePicker from "@/components/console/inputs/InputDatePicker.vue";
import ButtonBasic from "@/components/console/buttons/ButtonBasic.vue";

export default defineComponent({
  name: "LectureReservations",
  components: {
    ButtonBasic,
    InputDatePicker,
    ButtonTab,
    ReservationItem,
    PageHeader,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const lectureResourceId = route.path.split("/").slice(-2)[0];

    const state = reactive({
      lecturesReservations: computed(() => {
        return store.getters["lectures/lecturesReservations"];
      }),
      lectureReservationsMeta: computed(() => {
        return store.getters["lectures/lectureReservationsMeta"];
      }),
      filters: {
        startAt: "",
        endAt: "",
        status: "",
        page: 1,
      },
      activeBtn: computed(() => {
        return store.getters["lectures/lectureReservationsMeta"];
      }),
    });

    onBeforeMount(() => {
      getReservations(state.filters);
    });

    const getReservations = (params?) => {
      let payload = { ...state.filters, ...params };

      store
        .dispatch("lectures/getLectureReservations", {
          lectureResourceId: lectureResourceId,
          data: payload,
        })
        .then(() => {
          state.filters = {
            ...state.filters,
            ...state.lectureReservationsMeta.filters,
          };
        });
    };

    const actions = {
      goToLectures: () => {
        router.push({ name: "console.lectures" });
      },
      updateStartAt: (value) => {
        state.filters.startAt = value.toJSON();
      },
      updateEndAt: (value) => {
        state.filters.endAt = value.toJSON();
      },
      cancelSchedule: (reservation) => {
        store
          .dispatch("lectures/cancelReservedSchedule", {
            lectureResourceId: lectureResourceId,
            lectureScheduleResourceId: reservation.resourceId,
          })
          .then(() => {
            getReservations();
          });
      },
    };

    return { state, actions, lectureResourceId, getReservations };
  },
});
